var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"default-font",staticStyle:{"height":"100%"}},[(!_vm.isInvalid && _vm.orderData)?_c('div',[_c('div',{staticStyle:{"display":"flex","flex-direction":"row","justify-content":"space-between","align-items":"center","margin-top":"1rem","padding":"0.25rem 1rem 1rem"}},[_c('span',{staticClass:"orderId"},[_vm._v("Order ID: "+_vm._s(_vm.orderData.ORDER_ID))]),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":_vm.orderData.PDF_URL,"target":"_blank"}},[_c('v-btn',{staticStyle:{"text-transform":"none"},attrs:{"small":"","outlined":"","rounded":""}},[_c('span',{staticClass:"button-text"},[_vm._v("Download Receipt")]),_c('v-icon',{staticStyle:{"margin-left":"4px"},attrs:{"small":""}},[_vm._v(_vm._s(_vm.downloadIcon))])],1)],1)]),_c('hr',{staticClass:"dashed"}),_c('div',{staticClass:"pdf-center"},[(_vm.pdfUrl)?_c('pdf',{staticClass:"pdf_width",attrs:{"src":_vm.pdfUrl}}):_c('span',{staticClass:"center-text"},[_vm._v("Loading...")])],1),_c('v-footer',{staticStyle:{"box-shadow":"0 2px 3px -3px #4d4d4d","position":"fixed","bottom":"0","width":"100%"}},[_c('div',{staticStyle:{"display":"flex","justify-content":"center","align-items":"center","margin":"10px","width":"100%"}},[(_vm.orderData)?_c('div',{staticClass:"default-font",style:({
                background: '#3f51b5',
                width: '100%',
                position: 'relative',
                padding: '12px 20px',
                'border-radius': '5px',
                'font-size': '16px',
                'line-height': '24px',
                'text-align': 'center',
                color: 'white',
              }),on:{"click":_vm.openPaymentPage}},[(this.orderData && this.orderData.PAYMENT_STATUS === 'PENDING')?_c('span',[_vm._v("Pay ₹"+_vm._s(_vm.orderData.TOTAL_COST))]):_c('span',[_vm._v("Already Paid")])]):_vm._e()])]),(_vm.orderData)?_c('v-dialog',{attrs:{"fullscreen":"","hide-overlay":"","transition":"dialog-bottom-transition"},model:{value:(_vm.paymentDialog),callback:function ($$v) {_vm.paymentDialog=$$v},expression:"paymentDialog"}},[_c('v-card',{staticClass:"default-font flexcard",attrs:{"height":"100%"}},[_c('v-toolbar',{staticStyle:{"border-radius":"unset"},attrs:{"color":"#3f51b5"}},[_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){_vm.paymentDialog = false}}},[_c('v-icon',{attrs:{"color":"white"}},[_vm._v(_vm._s(_vm.crossSvg))])],1),_c('v-toolbar-title',{staticStyle:{"color":"white"}},[_vm._v("Payment Page")])],1),_c('iframe',{attrs:{"src":_vm.orderData.PAYMENT_LINK,"height":"100%","width":"100%"}})],1)],1):_vm._e()],1):_vm._e(),(_vm.isInvalid)?_c('div',[_c('InvalidPage')],1):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }