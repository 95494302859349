import Vue from "vue";
import Router from "vue-router";
import StatusPage from "@/pages/StatusPage";
import HomePage from "@/pages/HomePage";

Vue.use(Router);

let router = new Router({
    mode: "history",
    base: process.env.BASE_URL,
    routes : [
        {
            name: 'HomePage',
            path: '/',
            component: HomePage
        },
        {
            name: 'Status',
            path: '/status',
            component: StatusPage
        }
    ]
})

export default router