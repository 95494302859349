<template>
<div class="default-font" style="height: 100%">
  <div v-if="!isInvalid && orderData">
  <div style="display: flex;flex-direction: row;justify-content: space-between;align-items: center;margin-top: 1rem;padding: 0.25rem 1rem 1rem;">
    <span class="orderId">Order ID: {{orderData.ORDER_ID}}</span>
    <a :href="orderData.PDF_URL" target="_blank" style="text-decoration: none"><v-btn small outlined rounded style="text-transform: none"><span class="button-text">Download Receipt</span><v-icon small style="margin-left: 4px">{{downloadIcon}}</v-icon></v-btn></a>
  </div>
  <hr class="dashed"/>
  <div class="pdf-center">
    <pdf class="pdf_width" v-if="pdfUrl" :src="pdfUrl"></pdf>
    <span v-else class="center-text">Loading...</span>
  </div>
  <v-footer style="
      box-shadow: 0 2px 3px -3px #4d4d4d;position: fixed;bottom: 0;width: 100%">
    <div
        style="
              display: flex;
              justify-content: center;
              align-items: center;
              margin: 10px;
              width: 100%;
            "
    >
      <div
          v-if="orderData"
          @click="openPaymentPage"
          :style="{
                background: '#3f51b5',
                width: '100%',
                position: 'relative',
                padding: '12px 20px',
                'border-radius': '5px',
                'font-size': '16px',
                'line-height': '24px',
                'text-align': 'center',
                color: 'white',
              }"
          class="default-font"
      >
        <span v-if="this.orderData && this.orderData.PAYMENT_STATUS === 'PENDING'">Pay ₹{{orderData.TOTAL_COST}}</span>
        <span v-else>Already Paid</span>
      </div>
    </div>
  </v-footer>
      <v-dialog
          v-if="orderData"
          v-model="paymentDialog"
          fullscreen
          hide-overlay
          transition="dialog-bottom-transition"
      >
        <v-card height="100%" class="default-font flexcard">
          <v-toolbar
              style="border-radius: unset"
              color="#3f51b5"
          >
            <v-btn icon @click="paymentDialog = false">
              <v-icon color="white">{{ crossSvg }}</v-icon>
            </v-btn>
            <v-toolbar-title style="color: white"
            >Payment Page</v-toolbar-title
            >
          </v-toolbar>

          <iframe :src="orderData.PAYMENT_LINK" height="100%" width="100%"></iframe>
        </v-card>
      </v-dialog>
  </div>
  <div v-if="isInvalid">
    <InvalidPage></InvalidPage>
  </div>
</div>
</template>

<script>
import {mdiClose, mdiDownload} from "@mdi/js";
import pdf from 'vue-pdf'
import {db} from "@/main";
import InvalidPage from "@/pages/InvalidPage";
export default {
  name: "HomePage",
  components: {
    InvalidPage,
    pdf
  },
  created() {
    console.log(this.$route)
    this.fetchOrderData()
  },
  data() {
    return {
      downloadIcon: mdiDownload,
      orderData: null,
      pdfUrl: null,
      paymentDialog: false,
      crossSvg: mdiClose,
      isInvalid: false,
    }
  },
  methods: {
    fetchOrderData() {
      db.collection("STORES").doc(this.$route.query.storeID).collection('v2')
          .doc("MANUAL_ORDERS").collection("ORDERS").doc(this.$route.query.orderID).get()
          .then((doc) => {
            if (doc.exists) {
              this.orderData = doc.data()
              this.convertPdf()
            } else {
              this.isInvalid = true;
            }
          })
    },
    openPaymentPage() {
      if (this.orderData && this.orderData.PAYMENT_STATUS === 'PENDING') {
        this.paymentDialog = true
      }
    },
    convertPdf() {
      fetch('https://api.zyadashop.io/v2/utils/getImgFromPDF?url=' + this.orderData.PDF_URL).then((response) => response.json())
          .then((resp) => {
            if (resp.status === 1) {
              this.pdfUrl = resp.data
            }
          });
    },
  }
}
</script>

<style scoped>
.dashed {
  border-top: 1px dashed black;
}

.orderId {
  font-size: 16px;
}

.button-text {
  font-size: 14px;
}

.center-text {
  left: 0;
  line-height: 200px;
  margin-top: -100px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}

@media only screen and (max-width: 1024px) {
  .orderId {
    font-size: 14px;
  }
  .button-text {
    font-size: 12px;
  }
}
@media only screen and (min-width: 1024px) {
  .pdf_width {
    width: 50%;
  }
  .pdf-center {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
    width: 100%
  }
}
</style>