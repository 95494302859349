<template>
<div class="center-align default-font" style="display: flex;align-items: center;justify-content: center;flex-direction: column">
  <v-card flat>
    <v-img height="100" width="100" :src="require('@/assets/checked.svg')"></v-img>
  </v-card>
  <span style="margin-top: 16px;font-size: 24px;text-align: center">Payment Successful</span>
  <a style="text-decoration: none" :href="'https://gostore.app/' + $route.query.storeID"><v-btn color="#3f51b5" style="margin-top: 16px;color: white;padding: 24px 16px;text-transform: none;" elevation="0">Visit Our Online Store</v-btn></a>
</div>
</template>

<script>
export default {
  name: "StatusPage",
}
</script>

<style scoped>
.center-align {
  width: 300px;
  height: 300px;

  position: absolute;
  top:0;
  bottom: 0;
  left: 0;
  right: 0;

  margin: auto;
}
</style>