<template>
  <v-app>
    <v-app-bar
      app
    >

        <div v-if="storeData" class="d-flex align-center default-font-medium" style="font-size: 18px;margin-left: 16px">
          {{storeData.STORE_NAME}}
        </div>

      <v-spacer></v-spacer>

<!--      WHATSAPP AND CALL BUTTON TO BE PLACED HERE-->

      <a :href="'https://wa.me/+91' + (storeData && storeData.CUST_CARE)"
         target="_blank"><v-btn icon><v-icon>{{icons.whatsappIcon}}</v-icon></v-btn>
      </a>
      <a :href="'tel:' + (storeData && storeData.CUST_CARE)" target="_blank">
        <v-btn icon><v-icon>{{icons.phoneIcon}}</v-icon></v-btn>
      </a>
    </v-app-bar>

    <v-main>
<!--      <HomePage v-if="!isInvalid" :order-data="orderData" :pdf-url="pdfUrl"/>-->
<!--      <InvalidPage v-else/>-->
      <router-view></router-view>
    </v-main>
<!--    <v-main v-if="$route.path.includes('/status')">-->
<!--      <StatusPage v-if="orderData" :store-link="orderData.STORE_URL"></StatusPage>-->
<!--    </v-main>-->
  </v-app>
</template>

<script>
// import HomePage from "@/pages/HomePage";
import {db} from "@/main";
import {mdiWhatsapp, mdiPhone} from '@mdi/js'
// import InvalidPage from "@/pages/InvalidPage";
// import StatusPage from "@/pages/StatusPage";

export default {
  name: 'App',
  created() {
    this.fetchStoreData()
  },
  components: {
    // StatusPage,
    // InvalidPage,
    // HomePage,
  },
  methods: {
    fetchStoreData() {
      db.collection("STORES").doc(this.$route.query.storeID).get().then((doc) => {
        this.storeData = doc.data();
      })
    },
  },

  data: () => ({
    storeData: null,
    icons: {
      whatsappIcon: mdiWhatsapp,
      phoneIcon: mdiPhone,
    },
  }),
};
</script>
