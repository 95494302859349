import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import './style.css';
import firebase from "firebase/app";
import "firebase/firestore";
import router from "./router";
import './registerServiceWorker'



const firebaseConfig = {
  apiKey: "AIzaSyCakTCW5dI7Lk_nRsBPSA2wT_QvWO7JmuM",
  authDomain: "gostore.app",
  databaseURL: "https://shopkarle-a28da.firebaseio.com",
  projectId: "shopkarle-a28da",
  storageBucket: "shopkarle-a28da.appspot.com",
  messagingSenderId: "919289119849",
  appId: "1:919289119849:web:1a2c196e38f65ad9568ef8",
  measurementId: "G-W993E512Z5"
};
firebase.initializeApp(firebaseConfig);

export let firebaseCore = firebase;

firebase
    .firestore()
    .enablePersistence({
      synchronizeTabs: true
    })
    .catch(function(err) {
      if (err.code == "failed-precondition") {
        // Multiple tabs open, persistence can only be enabled
        // in one tab at a a time.
        // ...
      } else if (err.code == "unimplemented") {
        // The current browser does not support all of the
        // features required to enable persistence
        // ...
      }
    });

export const db = firebase.firestore();

new Vue({
  vuetify,
  router,
  render: h => h(App)
}).$mount('#app')
