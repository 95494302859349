<template>
<div class="default-font" style="display: flex;align-items: center;justify-content: center;font-size: 18px">
  <span>The receipt URL doesn't exists.</span>
</div>
</template>

<script>
export default {
  name: "InvalidPage"
}
</script>

<style scoped>
span {
  left: 0;
  line-height: 200px;
  margin-top: -100px;
  position: absolute;
  text-align: center;
  top: 50%;
  width: 100%;
}
</style>